import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import router from '@/plugins/vue-router';

if (process.env.VUE_APP_SENTRY_ENABLE) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(router),
    ],
    tracesSampleRate: 1,
  });
}
