import Vue from 'vue';
// @ts-ignore
import TTCheckbox from '@uikit/components/TTCheckbox/TTCheckbox';
// @ts-ignore
import TTNotify from '@uikit/components/TTNotify/TTNotify';
import TTAppBar from '@uikit/components/TTAppBar.vue';
import TTBtn from '@uikit/components/TTBtn/TTBtn.vue';
import TTNavigationDrawer from '@uikit/components/TTNavigationDrawer.vue';
import TTTextField from '@uikit/components/TTTextField/TTTextField.vue';
import TTToolbar from '@uikit/components/TTToolbar.vue';
import TTToolbarTitle from '@uikit/components/TTToolbarTitle.vue';
import TTView from '@views/TTView.vue';
import TTTooltip from '@uikit/components/TTTooltip/TTTooltip.vue';
import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';

import {
  i18n, router, store, vuetify, setupYandexMetrics,
} from '@/plugins';
import App from '@/App.vue';

Vue.component('TTNotify', TTNotify);
Vue.component(TTAppBar.name, TTAppBar);
Vue.component(TTBtn.name, TTBtn);
Vue.component(TTNavigationDrawer.name, TTNavigationDrawer);
Vue.component(TTTextField.name, TTTextField);
Vue.component(TTToolbar.name, TTToolbar);
Vue.component(TTToolbarTitle.name, TTToolbarTitle);
Vue.component('TTCheckbox', TTCheckbox);
Vue.component(TTView.name, TTView);
Vue.component(TTTooltip.name, TTTooltip);
Vue.component(TTPrompt.name, TTPrompt);

Vue.config.productionTip = false;

new Vue({
  name: 'TalentTech',
  i18n,
  router,
  store,
  vuetify,
  created() {
    setupYandexMetrics(Vue, router);
  },
  render: (h) => h(App),
}).$mount('#app');
